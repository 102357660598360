<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('users.add-user') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col class="px-4">
          <UserForm :user="{}"/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import UserForm from "@/components/admin/user/UserForm.vue";

export default {
  name: "AddUser",
  components: { NavBar, UserForm }
}
</script>
<style></style>
